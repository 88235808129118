import { useLoaderData, useSearchParams } from '@remix-run/react';
import {
  type ClientLoaderFunctionArgs,
  redirect,
  useNavigate,
} from '@remix-run/react';
import { useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { mutate } from 'swr';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { ConfirmCancelModalProvider } from '../components/ConfirmCancelModalContext';
import { SubscriptionChangeConfirm } from '../components/Product/SubscriptionChangeConfirm';
import { ProductSearchParam } from '../components/Product/utils';
import { ProvidersList } from '../components/ProvidersList';
import { PublicHomeLayout } from '../components/PublicHome/Layout';
import {
  UserContextProvider,
  useUser,
  useUserContext,
} from '../components/UserContext';
import config from '../config';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { useTitle } from '../hooks/useTitle';
import { apiService } from '../services/api-service';
import { store } from '../store/configureStore';
import { makeTitle } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const params = new URL(action.request.url).searchParams;
  const targetProduct = params.get(ProductSearchParam.TargetProduct);
  const cancel = params.get(ProductSearchParam.Cancel);

  if (!targetProduct && !cancel) {
    // this is just to avoid the request.
    throw redirect('/subscription/change');
  }

  const resp = await tokenWithRedirect(
    () => apiService.product.getPublicProducts(),
    action.request.url
  );

  return resp.data;
};

function ComponentInternal() {
  const data = useLoaderData<typeof clientLoader>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const user = useUser();
  const { updateUser } = useUserContext();

  const targetProduct = params.get(ProductSearchParam.TargetProduct);
  const targetPrice = params.get(ProductSearchParam.TargetPrice);
  const trial = params.get(ProductSearchParam.Trial);
  const cancel = params.get(ProductSearchParam.Cancel);
  const redirectTo =
    params.get(ProductSearchParam.RedirectTo) ?? '/subscription/change';

  const handleCancelConfirmation = useLiveCallback(() => {
    return navigate('/subscription/change');
  });

  const refreshOrg = useCallback(async () => {
    if (!user.organizer) return;
    const resp = await apiService.organization.getOrganization(
      user.organizer.orgId
    );
    const updatedOrganization = resp.data.organization;
    if (user.organizer) {
      updateUser({
        organizer: { ...user.organizer, organization: updatedOrganization },
      });
    }
    mutate(`/organizations/${user.organizer.orgId}/subscription`);
  }, [updateUser, user.organizer]);

  const handleConfirm = useLiveCallback(async () => {
    await refreshOrg();
    return navigate(redirectTo, { replace: true });
  });

  return (
    <SubscriptionChangeConfirm
      products={data}
      targetProductId={targetProduct}
      targetPriceId={targetPrice}
      trial={trial === 'true'}
      cancel={cancel === 'true'}
      onCancelConfirmation={handleCancelConfirmation}
      onConfirm={handleConfirm}
      redirectTo={redirectTo}
    />
  );
}

function Bootstrap() {
  useBootIntercom(useInstance(() => ['/subscription/change']));

  return null;
}

export function Component() {
  useTitle(makeTitle('Subscription'));
  const providers = [
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <IntercomProvider appId={config.misc.intercomAppId} />,
  ];

  return (
    <ProvidersList providers={providers}>
      <PublicHomeLayout>
        <div className='flex-1 min-h-0 overflow-y-auto scrollbar'>
          <ComponentInternal />
        </div>
        <Bootstrap />
      </PublicHomeLayout>
    </ProvidersList>
  );
}
